

#invitatie .card h4 {
    
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
  
}


#invitatie .card #mikey_img {
  width: 120px;
  position: absolute;
  left: 20%;
  top: 100px;
  
}

#invitatie .image {
  position: relative;
  top: 250px;
}





@media (max-width:1500px){
  #invitatie .card #mikey_img {
    width: 150px;
    left: 5%;
    top: 100px;
  }
}

@media (max-width: 500px){
  #invitatie .card #mikey_img {
    width: 90px;
    left: 1%;
    top: 100px;
  }
}

.image-gol {

  height: 260px;

}

.dir-img > img {
  width: 160px;
}
.dir-img{
  position: absolute;
  left: 40%;
  bottom: 90px ;
}
.dir-text {
    text-shadow: 1px  1px 2px  whitesmoke;
position: relative;
top: -145px;
left: -10px;
}
.dir-text2 {
    text-shadow: 1px  1px 2px  whitesmoke;
  position: relative;
  top: -125px;
  left: -16px;
}

.clock-img > img {
  width: 100px;
}
.clock-img{
  position: absolute;
  right: 12%;
  bottom: 160px;
}
.clock-text {
position: relative;
top: -62px;
}



.table-img > img {
  width: 220px;
}
.table-img{
  position: absolute;
 left: 2%;
  bottom: 90px;
}
.table-text {
position: relative;
top: -140px;
}
.table-text2 {
  position: relative;
  top: -150px;
}


@media (max-width: 500px) {
 
  
  .image-gol {
  
     height: 300px;
  
   }
   
   .dir-img > img {
     width: 150px;
   }
 
   .dir-img  {
    bottom: 70px;
  }
   .dir-text {
     top: -140px;
   }
   .dir-text2 {
    top: -120px;
  }
   
   .clock-img > img {
     width: 90px;
   }
  
   .clock-img  {
     right: 3%;
    bottom: 260px;
  }
   
   
   .table-img > img {
     width: 200px;
   }
   
   .table-img  {
    bottom: 180px;
  left: 1%;
  }

  .table-text {
    top: -130px;
  }
   
  .table-text2 {
    top: -140px;
  }
     
  }


