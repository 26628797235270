nav{
    top: 0;
    box-shadow: 0px 13px 24px 0px rgba(0,0,0,0.08);
    padding-top: .5rem;
    padding-bottom: .5rem;
   background-color: rgba(245, 245, 245, 0.164)!important ;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

nav .navbar-brand {
    color: green !important;
    font-family: 'Dancing Script', cursive;
    font-weight: bold;
}
nav .navbar-collapse.collapse{
    display: block;
    
}
@media (max-width: 991px){
    nav .navbar-collapse.collapse{
        overflow: hidden;

    }
    nav .navbar-collapse.active{
        height: 25vh !important;
    }
}
nav .navbar-collapse{
    transition: .6s all ease-in-out;
    height: 0  !important;
}